.LogoForm h2 {
  font-size: 20px;
  font-family: Arial;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.LogoForm h2:after {
  display: inline-block;
  content: "";
  height: 1px;
  background: #000000;
  position: absolute;
  width: 30px;
  top: 50%;
  margin-top: 0px;
  margin-left: 10px;
}

.LogoForm h2:before {
  display: inline-block;
  content: "";
  height: 1px;
  background: #000000;
  position: absolute;
  width: 30px;
  top: 50%;
  margin-top: 0px;
  margin-left: -40px;
}

.btnForm {
  min-width: 70% !important;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.headerListing {
  padding-left: 20px;
}

div.errorContainer {
  padding-left: 0;
  padding-right: 0;
}
