.containerHeadlinesForm {
  font-size: 0.7rem;
  text-align: justify;
}

.containerMERForm {
  font-size: 0.8rem;
  text-align: justify;
}
.containerFooterForm {
  font-size: 0.7rem;
}

.containerMainEditForm {
  font-size: 0.7rem;
}

.containerUserHeadlines {
  margin-top: 0;
  font-size: 1rem;
}

.MuiTextField-root > .MuiFormLabel-root.Mui-focused,
.MuiTextField-root > .MuiFormLabel-filled {
  top: 2px;
}

.css-jayakb-MuiFormControl-root > .MuiFormLabel-root {
  transform: translate(0px, -9px) scale(0.75);
}

.css-jayakb-MuiFormControl-root > .MuiFormLabel-root.Mui-focused,
.css-jayakb-MuiFormControl-root > .MuiFormLabel-filled {
  top: 2px;
  left: 10px;
}

.rct-title,
.rct-icon {
  font-size: small;
}

.rct-node-collapsed,
.rct-node-leaf {
  height: 18px;
}

.react-checkbox-tree label:hover {
  background: rgba(78, 194, 194, 0.3);
}

.recapSection {
  margin-top: 20px;
}

.nonprecise {
  color: rgba(0, 0, 0, 0.4);
}

.subMedia {
  margin-left: 20px;
  margin-bottom: 10px;
}

.fieldTitle {
  font-weight: bold;
}

.medias {
  margin-top: 10px;
}

.thematics {
  margin-top: 15px;
  text-align: justify;
}
.selectedThematics {
  margin-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 0.7rem;
}

.inputMatris {
  width: 100%;
}
